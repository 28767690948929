import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column ">
                <h1 className="title is-size-1">Night</h1>
                <div className="tags">
                  <h2 className="tag is-light is-rounded custom-tag-project">
                    Project
                  </h2>
                  <h2 className="tag is-light is-rounded custom-tag-til">
                    Today I learnt
                  </h2>
                  <h2 className="tag is-light is-rounded custom-tag-blog">
                    Blog
                  </h2>
                </div>
              </div>
              <div className="column is-three-quarters">
                <BlogRoll />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
