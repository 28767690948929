import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import IosTimeOutline from 'react-ionicons/lib/IosTimeOutline'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="column is-6" key={post.id}>
              <div className={'card is-' + post.frontmatter.type.toLowerCase()}>
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-48x48">
                        {post.frontmatter.featuredimage ? (
                          <div className="featured-thumbnail mt-1">
                            <PreviewCompatibleImage
                              imageInfo={{
                                image: post.frontmatter.featuredimage,
                                alt: `Featured image thumbnail for post ${post.frontmatter.title}`,
                              }}
                            />
                          </div>
                        ) : null}
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">{post.frontmatter.title}</p>
                    </div>
                  </div>

                  <div className="content is-small">
                    {post.frontmatter.description}
                  </div>

                  <p className="tags">

                    <span
                      className={
                        'tag is-light is-rounded custom-tag-' +
                        post.frontmatter.type.toLowerCase()
                      }
                    >
                      {post.frontmatter.type}
                    </span>

                    <span className="tag is-rounded">
                      {post.frontmatter.date}
                    </span>

                  </p>

                  <div className="tag is-white">
                    <span className="icon" ><IosTimeOutline /></span>
                    <span>&nbsp;{post.timeToRead} min read</span>
                  </div> 
                </div>
                <footer className="card-footer">
                  <Link className="card-footer-item " to={post.fields.slug}>
                    Read More →
                  </Link>
                </footer>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                type
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 80, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
